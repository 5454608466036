"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.load = void 0;

require("core-js/modules/es6.array.map.js");

require("core-js/modules/es6.map.js");

require("core-js/modules/es6.string.iterator.js");

require("core-js/modules/es6.object.to-string.js");

require("core-js/modules/es6.array.iterator.js");

require("core-js/modules/web.dom.iterable.js");

var _Tabs = require("./State/Ducks/Tabs/");

var _analytics = require("./Utils/analytics");

var _functions = require("./Utils/functions");

var _this = void 0;

var render = function render(config, store, elem) {
  var analyticsUtil = new _analytics.Analytics();
  import('./Views/Containers/TabsContainer').then(function (exports) {
    (0, _functions.renderComponent)(exports.default, store, config, elem, analyticsUtil);
  });
};

var loaderComponents = function loaderComponents(callback) {
  return function (args) {
    var moduleConfig = args.moduleConfig,
        moduleDiv = args.elem,
        store = args.store,
        moduleComponents = args.moduleComponents;

    if (!moduleDiv) {
      return;
    }

    var initialiseTabGroup = _Tabs.tabOperations.initialiseTabGroup;
    var state = store.getState();
    var schema = state.schema;
    var data = moduleComponents.map(function (comp) {
      var id = comp.get('sourceIdentity');
      var renderingId = comp.get('renderingId');

      if (!id) {
        return new Map();
      }

      var componentLabels = schema.get(id);
      var childNode = moduleDiv.querySelector("[data-react-id=\"".concat(renderingId, "\"]"));
      var children = childNode && childNode.innerHTML;
      var initScript = childNode.querySelector("[data-init]") && childNode.querySelector("[data-init]").dataset.init;
      var config = comp.set('id', id).set('config', moduleConfig).set('label', componentLabels).set('children', children).set('initScript', initScript);
      return config;
    });
    store.dispatch(initialiseTabGroup(data.size));
    callback(data, store, moduleDiv);
  };
};

var loadComponents = loaderComponents(function () {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  render.apply(_this, args);
});

var bootstrap = function bootstrap() {
  return function () {
    for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    var store = args[0],
        schemaName = args[1],
        elem = args[2],
        sourceIdentifier = args[3],
        moduleComponents = args[4]; //TO BE REMOVED! MPS AB testing custom styling on Tab wrapper

    var customTabsWrapperClass = elem && elem.dataset && elem.dataset.customCssClass;
    var tabsWrapperNode = document.getElementsByClassName('tabs-wrapper');
    customTabsWrapperClass && tabsWrapperNode.length > 0 && tabsWrapperNode[0].classList.add(customTabsWrapperClass); //END

    var state = store.getState();
    var schema = state.schema;
    var moduleConfig = sourceIdentifier ? schema.get(sourceIdentifier) : new Map();
    loadComponents({
      moduleConfig: moduleConfig,
      schemaName: schemaName,
      elem: elem,
      store: store,
      moduleComponents: moduleComponents
    });
  };
};

var load = bootstrap();
exports.load = load;